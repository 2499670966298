<template>
  <div >
    <div v-if="storeLoaded" class="logins-container" :key="hierarchyBasicDetails.id">
    <div class="input-display">
      <div class="left-label left-input apply-theme-color">
        <div v-if="loginDetails.length === 0" class="apply-theme-color">
          {{$t("no_login_for_hierarchy")}}
       </div>
       <div v-else class="apply-theme-color">
          {{$t("active_logins_for_hierarchy")}}
       </div>
      </div>
      <div v-if="hierarchyBasicDetails.parentId" class="right-create-button">
        <Button :label="$t(`create_login`)" :type="'solid'" @click="addNewLogin"/>
      </div>
    </div>
    <div v-for="login in loginDetails" :key="login.id" class="login-display">
      <div class="left-input">
        <p class="apply-theme-color">{{login.text}}</p>
      </div>
      <div class="right-input">
        <div v-if="hierarchyBasicDetails.parentId" class="display-inlined blue-color"  @click="activateChangePassword(login)">{{$t("change_password")}}</div>
      </div>
    </div>
    <Modal v-if="createNewLogin" :headingText="$t(`create_login`)" @close="close()" @click="onOk()" :disableConfirmBtn="!overAllErrorNewLogin">
      <div slot="body">
        <div name = "body" >
            <div class="adjust-width">
            <Input :label="$t(`hierarchy_id`)"  :orientation="'v'" name="hierarchyId" v-model="hierarchyBasicDetails.id" :isDisabled="true"/>
            <Input :label="$t(`username`)" :orientation="'v'" name="Username" v-model="newUsername" :hasError="newUsernameError" :errorMessage="$t(newUsernameErrorMessage)"/>
            <Input :label="$t(`description`)" :orientation="'v'" name="Description" v-model="newDescription" />
            <Input :label="$t(`password`)" :orientation="'v'" name="Password" v-model="newPassword" :type="'password'" :hasError="newPasswordError" :errorMessage="$t(pwdErrorMessage)" :hasInfo="newPassword === ''" :infoMessage="$t(`password_guidelines`)"/>
            <div :class="{ 'additional-margin-for-big-info-message' : !newPasswordError && newPassword === ''}">
              <Input :label="$t(`reenter_password`)" :orientation="'v'" name="RePassword" v-model="rePassword" :type="'password'" :hasError="newRePasswordError" :errorMessage="$t(rePasswordErrorMessage)"/>
            </div>
            <Input :label="$t(`_primary_phone_number`)" :orientation="'v'" name="PrimaryNumber" v-model="primaryNumber"/>
            <Input :label="$t(`email`)" :orientation="'v'" name="email" v-model="email"/>
            </div>
            <Select :label="$t(`type_of_patients_added`)" :allOptions="showTypeOfPatients" name="typeOfPatientsOptions" :hasError="newTypePatientError" :errorMessage="$t(`Add Type Of Patient`)" :orientation="'v'" v-model="typeOfPatients" :is-multi-select="false"/>
            <Select :label="$t(`access_type`)" :allOptions="showAccessTypeOptions" name="accessTypeOptions" :hasError="newAccessTypeError" :errorMessage="$t(`Add Access Type Of Patient`)" :orientation="'v'" v-model="accessType" :is-multi-select="false"/>
        </div>
      </div>
    </Modal>
    <Modal v-if="changePassword" :headingText="$t(`change_password`)" @close="onChangePasswordClose()" @click="onChangePassword()" :disableConfirmBtn="!resetPasswordOverAllError">
      <div slot="body">
        <div name = "body" >
            <Input :label="$t(`username`)"  :orientation="'v'" name="Username" v-model="selectedUser.text" :isDisabled="true"/>
            <Input :label="$t(`new_password`)" :orientation="'v'" name="Password" v-model="resetPassword" :type="'password'" :hasError="resetPasswordError" :errorMessage="$t(pwdErrorMessage)" :hasInfo="resetPassword === ''" :infoMessage="$t(`password_guidelines`)"/>
            <div :class="{ 'additional-margin-for-big-info-message' : !resetPasswordError && resetPassword === ''}">
              <Input :label="$t(`password_reenter`)" :orientation="'v'" name="RePassword" v-model="reResetPassword" :type="'password'" :hasError="resetRePasswordError" :errorMessage="$t(rePasswordErrorMessage)"/>
            </div>
        </div>
      </div>
    </Modal>
    </div>
    <div v-else class="hierarchy-details-container">
      <p class="apply-theme-color">{{$t("select_hierarchy_see_details")}}</p>
    </div>
  </div>
</template>

<script src="https://cdnjs.cloudflare.com/ajax/libs/crypto-js/3.1.9-1/crypto-js.js"></script>
<script>
import { mapState, mapActions } from 'vuex'
import Button from '@/app/shared/components/Button.vue'
import Input from '@/app/shared/components/Input.vue'
import Modal from '@/app/shared/components/Modal.vue'
import Select from '@/app/shared/components/Select.vue'
import { isNotNullNorEmpty } from '@/app/shared/utils/Objects'
export default {
  name: 'LoginsManagement',
  data () {
    return {
      newUsername: '',
      newPassword: '',
      newDescription: '',
      createNewLogin: false,
      rePassword: '',
      primaryNumber: '',
      email: '',
      typeOfPatients: [],
      accessType: [],
      resetPassword: '',
      reResetPassword: '',
      selectedUser: {},
      changePassword: false,
      resetPasswordError: false,
      resetRePasswordError: false,
      rePasswordErrorMessage: '',
      newPasswordError: false,
      newRePasswordError: false,
      pwdErrorMessage: '',
      newUsernameError: true,
      newUsernameErrorMessage: '',
      newTypePatientError: true,
      newAccessTypeError: true
    }
  },
  components: {
    Button,
    Input,
    Modal,
    Select
  },
  async mounted () {
  },
  methods: {
    ...mapActions('HiearachyManagement', ['updateBasicDetails', 'toggleBasicDetailsEdit', 'getNewLoginConfig', 'createNewLoginInHierarchy', 'updatePasswordForLogin', 'getHierarchyDetails']),
    async addNewLogin () {
      this.newUsername = ''
      this.newPassword = ''
      this.rePassword = ''
      await this.getNewLoginConfig({ params: { hierarchyId: this.hierarchyBasicDetails.id } })
      this.createNewLogin = true
    },
    close () {
      this.createNewLogin = false
    },
    async onOk () {
      if (!this.newRePasswordError && !this.newRePasswordError) {
        // var key = CryptoJS.enc.Utf8.parse('8080808080808080');
        // var iv = CryptoJS.enc.Utf8.parse('8080808080808080');
        // var encryptedPassword = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(this.newPassword), key, {keySize: 128 / 8, iv: iv, mode: CryptoJS.mode.CBC,padding: CryptoJS.pad.Pkcs7}).toString();
        // await this.createNewLoginInHierarchy({ data: { hierarchyId: this.hierarchyBasicDetails.id, username: this.newUsername, password: encryptedPassword, description: this.newDescription, primaryPhone: this.primaryNumber, email: this.email, typeOfPatientsAdded: this.typeOfPatients.Key, accessType: this.accessType.Key, isEncrypted: true } })
        await this.createNewLoginInHierarchy({ data: { hierarchyId: this.hierarchyBasicDetails.id, username: this.newUsername, password: this.newPassword, description: this.newDescription, contactNumber: this.primaryNumber, emailId: this.email, typeOfPatientsAdded: this.typeOfPatients.Key, accessType: this.accessType.Key, ssoUserToBeCreated: true } })
        await this.getHierarchyDetails({ hierarchyId: this.hierarchyBasicDetails.id })
        this.createNewLogin = false
      }
    },
    async onChangePassword () {
      if (!this.resetPasswordError && !this.resetRePasswordError) {
        await this.updatePasswordForLogin({ data: { username: this.selectedUser.text, password: this.resetPassword } })
        this.changePassword = false
      }
    },
    async onChangePasswordClose () {
      this.changePassword = false
      this.resetPassword = ''
      this.reResetPassword = ''
    },
    async activateChangePassword (user) {
      this.resetPassword = ''
      this.reResetPassword = ''
      this.selectedUser = user
      this.changePassword = true
    },
    validatePassword (password) {
      if (password.length === 0) {
        return true
      } else if (password.length < 8) {
        this.pwdErrorMessage = 'too_short'
        return false
      } else if (password.length > 20) {
        this.pwdErrorMessage = 'too_long'
        return false
      } else if (password.search(/\d/) === -1) {
        this.pwdErrorMessage = 'no_num'
        return false
      } else if (password.search(/[a-zA-Z]/) === -1) {
        this.pwdErrorMessage = 'no_letter'
        return false
      } else if (password.search(/[_\-$@$!%*#?&]/) === -1) {
        this.pwdErrorMessage = 'no_spl_char'
        return false
      }
      return true
    },
    checkReEnterPasswordError(password, resetPassword) {
      if (password !== '') {
        this.rePasswordErrorMessage = resetPassword === '' ? 'password_reenter' : 'passwords_did_not_match'
      }
      return password !== '' && (password !== resetPassword || resetPassword === '')
    }
  },
  computed: {
    ...mapState('HiearachyManagement', [
      'loginDetails',
      'typeOfPatientsOptions',
      'accessTypeOptions',
      'hierarchyBasicDetails'
    ]),
    storeLoaded () {
      return this.loginDetails !== null && this.hierarchyBasicDetails !== null
    },
    showAccessTypeOptions () {
      return this.accessTypeOptions.map(elm => { return { Key: elm, Value: elm } })
    },
    showTypeOfPatients () {
      return this.typeOfPatientsOptions.map(elm => { return { Key: elm, Value: elm } })
    },
    overAllErrorNewLogin () {
      return (isNotNullNorEmpty(this.newPassword) && isNotNullNorEmpty(this.rePassword) && !this.newPasswordError && !this.newRePasswordError && !this.newUsernameError && !this.newTypePatientError && !this.newAccessTypeError)
    },
    resetPasswordOverAllError () {
      return ( isNotNullNorEmpty(this.resetPassword) && isNotNullNorEmpty(this.reResetPassword) && !this.resetPasswordError && !this.resetRePasswordError)
    }
  },
  watch: {
    newPassword () {
      this.newPasswordError = !(this.validatePassword(this.newPassword))
      this.newRePasswordError = this.checkReEnterPasswordError(this.newPassword, this.rePassword)
    },
    rePassword () {
      this.newRePasswordError = this.checkReEnterPasswordError(this.newPassword, this.rePassword)
    },
    resetPassword () {
      this.resetPasswordError = !(this.validatePassword(this.resetPassword))
      this.resetRePasswordError = this.checkReEnterPasswordError(this.resetPassword, this.reResetPassword)
    },
    reResetPassword () {
      this.resetRePasswordError = this.checkReEnterPasswordError(this.resetPassword, this.reResetPassword)
    },
    newUsername () {
      if (this.newUsername.length > 255) {
        this.newUsernameError = true
        this.newUsernameErrorMessage = 'The username is too long - it should be less than max characters 255'
      } else if (this.newUsername.length === 0) {
        this.newUsernameError = true
        this.newUsernameErrorMessage = 'error_username_invalid'
      } else {
        this.newUsernameError = false
      }
    },
    typeOfPatients () {
      if (this.typeOfPatients.Value !== '' && this.typeOfPatients.Value !== null) {
        this.newTypePatientError = false
      }
    },
    accessType () {
      if (this.accessType.Value !== '' && this.accessType.Value !== null) {
        this.newAccessTypeError = false
      }
    }

  }
}
</script>
<style scoped>
.logins-container {
  padding-top: 3%;
  padding-left: 2%;
  padding-right: 2%;
}
.input-display {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}
.left-label {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  padding-left: 0% !important;
}
.login-display {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  border-width: 1px;
  border-color: #D6D8E7;
  border-style: solid;
  border-radius: 8px;
  padding-top: 2%;
  padding-bottom: 2%;
  margin-top: 1%;
}
.left-input {
  flex: 1 0 40%;
  padding-left: 3%;
}
.right-input {
  padding-right: 2%;
}
.display-inlined {
  display: inline;
}
.place-trash-icon {
  padding-left: 5%;
  border-left-width: 2px !important;
  border-left: solid;
  border-left-color: #D6D8E7;
  margin-left: 4%;
}
.adjust-width {
    width: calc(100% - 32px);
}
.blue-color {
  color: #1155CC;
}
.apply-theme-color {
  color:var(--text-primary-color);
}
.hierarchy-details-container {
  padding-left: 1%;
  padding-right: 1%;
  padding-top: 2%;
}
.additional-margin-for-big-info-message {
  margin-top: 12%;
}
</style>
